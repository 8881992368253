import axios from "axios";
import config from "../config";

export const prestataireStore = {
    namespaced: true,
    state: () => ({
        "prestataires": [],
        "selected_prestataire": null,
    }),
    mutations: {
        addPrestataire(state, prestataire) {
            state.prestataires = [prestataire, ...state.prestataires];
        },
        setPrestataires(state, prestataires) {
            state.prestataires = prestataires;
        },
        updatePrestataire(state, prestataire) {
            state.prestataires = state.prestataires.map((item) => item.id === prestataire.id ? prestataire : item);
        },
        setSelectedPrestataire(state, prestataire) {
            state.selected_prestataire = prestataire;
        }
    },
    actions: {
        async fetchPrestataires({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/prestataires/", axiosConfig);
                commit("setPrestataires", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }

        },
        async getPrestataireById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/prestataires/${id}`, axiosConfig);
                commit("setSelectedPrestataire", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }

        },
    },
    getters: {
        allPrestataires(state) {
            return state.prestataires;
        },
        getSelectedPrestataire(state) {
            return state.selected_prestataire;
        },
    },
    modules: {},
};