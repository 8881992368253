<template>
  <div class="home">
    <v-container>
      <v-row>
<!--        <v-col cols="12" md="4" v-if="displayCards.value === 'true' || ticket_stats.opened_tickets !== 0">
          <v-card color="#E7B05C" class="ma-4 pa-3 text-center" to="/tickets?state=waiting">
            <h2 class="white&#45;&#45;text" v-if="ticket_stats.opened_tickets !== undefined">{{ ticket_stats.opened_tickets }} ticket<span v-if="ticket_stats.opened_tickets > 1">s</span><br> en cours d'assignation</h2>
            <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
          </v-card>
        </v-col>-->
        <v-col cols="12" md="4" v-if="(displayCards.value === 'true' || ticket_stats.unassigned_tickets !== 0) && isCollabOrAdmin">
          <v-card color="red" class="ma-4 pa-3 text-center" to="/tickets?state=unassigned">
            <h2 class="white--text" v-if="ticket_stats.unassigned_tickets !== undefined">{{ ticket_stats.unassigned_tickets }} ticket<span v-if="ticket_stats.unassigned_tickets > 1">s</span><br> non assigné<span v-if="ticket_stats.unassigned_tickets > 1">s</span></h2>
            <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" v-if="(displayCards.value === 'true'|| ticket_stats.waiting_interventions !== 0) && isResponsableOrAdmin">
        <v-card color="#706F6F" class="ma-4 pa-3 text-center" to="/interventions?state=waiting">
          <h2 class="white--text" v-if="ticket_stats.waiting_interventions !== undefined">{{ ticket_stats.waiting_interventions }} intervention<span v-if="ticket_stats.waiting_interventions > 1">s</span><br> en attente de retour</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" v-if="(displayCards.value === 'true' || ticket_stats.interventions_to_valid !== 0) && isResponsableOrAdmin">
        <v-card color="primary" class="ma-4 pa-3 text-center" to="/interventions?state=validation">
          <h2 class="white--text" v-if="ticket_stats.interventions_to_valid !== undefined">{{ ticket_stats.interventions_to_valid }} intervention<span v-if="ticket_stats.interventions_to_valid> 1">s</span><br> à valider</h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" v-if="(displayCards.value === 'true' || ticket_stats.interventions_to_note !== 0)">
        <v-card class="ma-4 pa-3 text-center blue darken-3" to="/interventions?state=to_note">
          <h2 class="white--text" v-if="ticket_stats.interventions_to_note !== undefined">
            {{ ticket_stats.interventions_to_note }}
            intervention<span v-if="ticket_stats.interventions_to_note > 1">s</span>
            <br> à noter
          </h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
        <v-col cols="12" md="4" v-if="(displayCards.value === 'true' || ticket_stats.running_interventions !== 0) && isCollabOrAdmin">
        <v-card class="ma-4 pa-3 text-center orange darken-3" to="/interventions?state=running">
          <h2 class="white--text" v-if="ticket_stats.running_interventions !== undefined">
            {{ ticket_stats.running_interventions }}
            intervention<span v-if="ticket_stats.running_interventions > 1">s</span>
            <br> en cours
          </h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
        <v-col cols="12" md="4" v-if="(displayCards.value === 'true' || ticket_stats.assigned_interventions !== 0) && isCollabOrAdmin">
        <v-card class="ma-4 pa-3 text-center green darken-3" to="/interventions?state=assigned">
          <h2 class="white--text" v-if="ticket_stats.assigned_interventions !== undefined">
            {{ ticket_stats.assigned_interventions }}
            intervention<span v-if="ticket_stats.assigned_interventions > 1">s</span>
            <br> assignée<span v-if="ticket_stats.assigned_interventions > 1">s</span>
          </h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
        <v-col cols="12" md="4" v-if="(displayCards.value === 'true' ||ticket_stats.next_controls !== 0) && isResponsableOrAdmin">
        <v-card class="ma-4 pa-3 text-center yellow darken-3" to="/contracts?soon=true">
          <h2 class="white--text" v-if="ticket_stats.next_controls !== undefined">
            {{ ticket_stats.next_controls }}
            contrat<span v-if="ticket_stats.next_controls > 1">s</span> <br/> programmé<span v-if="ticket_stats.next_controls > 1">s</span>
          </h2>
          <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
        </v-card>
      </v-col>
      </v-row>

      <v-row>
        <v-col offset="1" cols="10">
          <Agenda />
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>

import axios from "axios";
import config from "@/config";
import Agenda from "@/views/Agenda";
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  name: 'Home',
  components: {Agenda},
  mixins: [AuthMixin],
  async mounted() {
    let loginConfig = this.$store.getters["users/authentificationConfig"];
    try {
      let resp = await axios.get(config.BASE_URL + "/api/tickets/stats", loginConfig);
      this.ticket_stats = resp.data;
    } catch (e) {
      await this.$store.dispatch("alerts/handleAxiosError", e);
    }
    if (this.$store.getters["config/allConfigs"].length === 0) {
      await this.$store.dispatch("config/fetchConfigs");
    }
    this.createRefresher();
  },
  data() {
    return {
      "ticket_stats": {
        "opened_tickets": undefined,
        "urgent_tickets": undefined,
        "waiting_interventions": undefined,
        "interventions_to_valid": undefined,
        "unassigned_tickets": undefined,
        "interventions_to_note": undefined,
        "next_controls": undefined,
        "running_interventions": undefined,
        "assigned_interventions": undefined,
    },
      "refresher": null,
    }
  },
  computed: {
    displayCards() {
      let display_cards = this.$store.getters["config/displayCards"]
      return display_cards !== undefined ? display_cards : {value: false};
    },
  },
  methods: {
    createRefresher() {
      let that = this;
      this.refresher = setInterval(async function () {
        let loginConfig = that.$store.getters["users/authentificationConfig"];
        try {
          let resp = await axios.get(config.BASE_URL + "/api/tickets/stats", loginConfig);
          that.ticket_stats = resp.data;
        } catch (e) {
          await that.$store.dispatch("alerts/handleAxiosError", e);
        }
      }, 10000);
    }
  },
  beforeDestroy() {
    if(this.refresher !== null) clearInterval(this.refresher);
  }
}
</script>
