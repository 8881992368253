import dayjs from "dayjs";

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

function site_name(site) {
    let siteObject = site;
    if (siteObject === null || siteObject === undefined) return "Non défini";
    let siteText = siteObject.name;
    if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
    }
    if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
    }
    return siteText;
}

export const TicketStatStore = {
    namespaced: true,
    state() {
        return {
            selected_site: null,
            selected_month: null,
            selected_year: dayjs().year(),
        }
    },
    mutations: {
        setSelectedYear(state, value) {
            state.selected_year = value;
        },
        setSelectedMonth(state, value) {
            state.selected_month = value;
        },
        setSelectedSite(state, value) {
           state.selected_site = value;
        }
    },
    getters: {
        getSelectedYear(state) {
            return state.selected_year;
        },
        getSelectedMonth(state) {
            return state.selected_month;
        },
        getSelectedSite(state) {
            return state.selected_site;
        },
        tickets_by_state(state, getters, rootState, rootGetters) {
            let tickets = rootGetters["tickets/allTickets"]
                .filter(ticket => state.selected_site !== null ? site_name(ticket.asset?.site).indexOf(state.selected_site) !== -1 : true)
                .filter(ticket => state.selected_year !== null ? dayjs(ticket.date_creation).year() === state.selected_year : true)
                .filter(ticket => state.selected_month !== null ? dayjs(ticket.date_creation).month() === state.selected_month : true);
            return groupBy(tickets, "state");
        },
        count_tickets_by_state(state, getters) {
            let count_tickets = {};
            for (const [state, tickets] of Object.entries(getters["tickets_by_state"])) {
                count_tickets[state] = tickets.length;
            }
            return count_tickets;
        },
        active_years: function (state, getters, rootState, rootGetters) {
            return rootGetters["tickets/allTickets"]
                .filter((ticket) => ticket.date_creation !== null)
                .map((ticket) => dayjs(ticket.date_creation).year())
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
        },

    },
};