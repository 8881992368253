import config from "@/config";
import axios from "axios";

/**
 * toast notification module
 */
export const planStore = {
    namespaced: true,
    state() {
        return {
            plans: [],
            selected_plan: null,
            loading_status: "INITIAL",
        }
    },
    mutations: {
        addPlan(state, plan) {
            state.plans = [plan,...state.plans]
        },
        deletePlan(state, plan) {
          state.plans = state.plans.filter((p) => plan.id !== p.id);
        },
        setPlans(state, plans) {
            state.plans = plans;
        },
        setSelectedPlan(state, plan) {
            state.selected_plan = plan;
        },
        setLoadingStatus(state, status) {
            state.loading_status = status;
        }
    },
    getters: {
        plans(state) {
            return state.plans;
        },
        loading_status(state) {
            return state.loading_status;
        },
        selected_plan(state) {
            return state.selected_plan;
        }
    },
    actions: {
        async fetchPlans({commit, dispatch, rootGetters}) {
            commit("setLoadingStatus", "LOADING");
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/plans/`;
            try {
                let response = await axios.get(url, axiosConfig);
                commit("setLoadingStatus", "LOADED");
                commit("setPlans", response.data);
                return "success";
            } catch (e) {
                commit("setLoadingStatus", "ERROR");
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async fetchPlanById({commit, dispatch, rootGetters}, planId) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/plans/${planId}`;
            try {
                let response = await axios.get(url, axiosConfig);
                commit("setSelectedPlan", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async printPlan({dispatch, rootGetters}, payload) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/plans/${payload.planId}/generate/`;
            try {
                let response = await axios.post(url, {image: payload.image, plan: payload.planId}, axiosConfig);
                let downloadUrl = response.data.url;
                if (downloadUrl !== null) {
                  var link = document.createElement("a");
                  link.download = `${payload.plan.name}.pdf`;
                  link.href = `${config.BASE_URL}${downloadUrl}`;
                  link.target = "_blank";
                  link.click();
                }
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postPlan({commit, dispatch, rootGetters}, plan) {
            let formData = new FormData();
            formData.append("base_image", plan.base_image);
            formData.append("name", plan.name);
            formData.append("category", plan.category);
            formData.append("site", plan.site);
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + `/api/plans/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    ...axiosConfig
                });
                commit("addPlan", response.data);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async deletePlan({commit, dispatch, rootGetters}, plan) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.delete(`${config.BASE_URL}/api/plans/${plan.id}`, axiosConfig)
                commit("deletePlan", plan);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        }
    }
}
