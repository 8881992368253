export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function combineArrays(a, b) {
  return [...new Set([...a, ...b])];
}
export function convertToSelect(objectType) {
    let select = []
    for(const key in objectType) {
        select.push({name: objectType[key], value: key});
    }
    return select;
}