import axios from "axios";
import config from "@/config";

export const PermissionsStore = {
    namespaced: true,
    state() {
        return {
            "selected_user_permissions": null,
            "permissions": [],
        }
    },
    mutations: {
        setSelectedUserPermissions(state, permissions) {
            state.selected_user_permissions = permissions;
        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
        }
    },
    getters: {
        getSelectedUserPermissions(state) {
            return state.selected_user_permissions;
        },
        getPermissions(state) {
            return state.permissions;
        }
    },
    actions: {
        async fetchUsersPermissions({commit, dispatch, rootGetters}, user) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/core/user_permissions/${user.id}/`;
            try {
                let response = await axios.get(url, axiosConfig);
                commit("setSelectedUserPermissions", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }

        },
        async fetchPermissions({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/core/permissions/`;
            try {
                let response = await axios.get(url, axiosConfig);
                commit("setPermissions", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async udpdateUsersPermissions({commit, dispatch, rootGetters}, user) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/core/user_permissions/${user.id}/`;
            try {
                let response = await axios.patch(url, user, axiosConfig);
                commit("setSelectedUserPermissions", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        }
    }
}