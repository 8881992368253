import axios from "axios";
import config from "../config";
export const categoryStore = {
    namespaced: true,
    state: () => ({
        "categories": [],
        "selected_category": null,
    }),
    mutations: {
        addCategory(state, category) {
            state.categories.push(category);
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        updateCategory(state, category) {
            state.categories = state.categories.map((item) => item.id === category.id ? category : item);
        },
        setSelectedCategory(state, category) {
            state.selected_category = category;
        },
        removeCategory(state, category) {
            state.categories = state.categories.filter(item => item.id !== category.id);
        }
    },
    actions: {
        fetchCategories({commit, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            axios.get(config.BASE_URL + "/api/core/categories/?include=sites", axiosConfig)
                .then(function (response) {
                    if (response.status === 200) {
                        commit('setCategories', response.data);
                    } else {
                        throw Error("Impossible de charger les categories");
                    }
                });
        },
        async postCategory({commit, rootGetters}, category) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            let response = await axios.post(config.BASE_URL + "/api/core/categories/", category, axiosConfig)
            if (response.status === 201) {
                var newCategory = response.data;
                commit("addCategory", newCategory);
                commit("setSelectedCategory", newCategory);
                return "success";
            } else {
                throw Error(response.data);
            }
        },
        async deleteCategory({commit, rootGetters}, category) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            let response = await axios.delete(config.BASE_URL + "/api/core/categories/" + category.id + "/", axiosConfig)
            if (response.status >= 200 && response.status <= 300) {
                commit("removeCategory", category);
                commit("setSelectedCategory", null);
                return "success";
            } else {
                throw Error(response.data);
            }
        },
        async patchCategory({commit, rootGetters, dispatch}, category) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/core/categories/" + category.id + "/";
            try {
                let response = await axios.patch(url, category, axiosConfig);
                commit("updateCategory", response.data);
                return "success";
            } catch(e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        }
    },
    getters: {
        allCategories(state) {
            return state.categories;
        },
        domaines(state) {
            return state.categories.filter((item) => item.type === "domaine");
        },
        metiers(state) {
            return state.categories.filter(item => item.type === "metier");
        },
        getSelectedCategory(state) {
            return state.selected_category;
        }
    }
};