export const DrawerStore = {
    namespaced: true,
    state() {
        return {
            dialog: false,
            component: null,
            properties: {},
        }
    },
    mutations: {
        setComponent(state, value) {
            state.component = value;
        },
        setProperties(state, value) {
            state.properties = value;
        },
        setDialog(state, value) {
            state.dialog = value;
        },
    },
    getters: {
        getComponent(state) {
            return state.component;
        },
        getProperties(state) {
            return state.properties;
        },
        getDialog(state) {
            return state.dialog;
        }
    },
    actions: {
        openDialog(context, payload) {
            context.commit("setProperties", payload.properties);
            context.commit("setComponent", payload.component);
            context.commit("setDialog", true);
        },

        closeDialog(context) {
            context.commit("setDialog", false);
            context.commit("setComponent", null);
            context.commit("setProperties", {});
        }
    }
}