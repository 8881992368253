import dayjs from "dayjs";

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
function getInterventionYear(intervention) {
    if (intervention.date_intervention) {
        return dayjs(intervention.date_intervention).year();
    } else if(intervention.date_realise) {
        return dayjs(intervention.date_realise).year();
    } else if(intervention.ticket.date_creation) {
        return dayjs(intervention.ticket.date_creation).year();
    } else {
        return "Pas de date";
    }
}

export const InterventionStatStore = {
    namespaced: true,
    state() {
        return {
            selected_site: null,
            selected_site_tree: [],
        }
    },
    mutations: {
        setSelectedSite(state, payload) {
            state.selected_site = payload;
        },
        setSelectedSiteTree(state, payload) {
            state.selected_site_tree = payload;
        },
    },
    getters: {
        getSelectedSite(state) {
            return state.selected_site;
        },
        getSelectedSiteTree(state) {
            return state.selected_site_tree;
        },
        interventions_per_prestataire: function (state, getters) {
            let interventions = getters["interventions_finances"]
                .filter(intervention => intervention?.prestataire != null)
                .map(intervention => ({
                    ...intervention,
                    intervenant_id: intervention?.prestataire?.id,
                }));
            let interventions_by_intervenant = groupBy(interventions, "intervenant_id");
            let intervenants_stats = [];
            for (const [intervenant_id, intervenant_interventions] of Object.entries(interventions_by_intervenant)) {
                let notes_intervenant = intervenant_interventions
                    .filter(interv => interv.note !== null)
                    .map(interv => parseFloat(interv.note))
                let delais_intervenant = intervenant_interventions
                    .filter(interv => interv.date_reponse !== null && interv.date_creation !== null)
                    .map(interv => dayjs(interv.date_reponse).diff(dayjs(interv.date_creation), "hour"))
                let delais_realisation = intervenant_interventions
                    .filter(interv => interv.date_intervention !== null && interv.date_realisation !== null)
                    .map(interv => dayjs(interv.date_realisation).diff(dayjs(interv.date_intervention), "hour"))
                let ca_intervenant = intervenant_interventions
                    .filter(interv => interv.cout_achat !== null)
                    .map(interv => parseFloat(interv.cout_achat))
                    .reduce((a, b) => a + b, 0)
                let realises_intervenant = intervenant_interventions
                    .filter(interv => interv.date_realisation !== null).length;

                intervenants_stats.push({
                    note_globale: notes_intervenant.reduce((a, b) => a + b, 0) / notes_intervenant.length,
                    delai_reponse: (delais_intervenant.reduce((a, b) => a + b, 0) / delais_intervenant.length) || 0,
                    delai_realisation: (delais_realisation.reduce((a, b) => a + b, 0) / delais_realisation.length) || 0,
                    ca: ca_intervenant,
                    interventions_realises: realises_intervenant,
                    intervenant_id: parseInt(intervenant_id),
                });
            }
            return intervenants_stats;

        },
        interventions_with_status(state, getters, rootState, rootGetters) {
            return getters["interventions_finances"]
                .map(intervention => ({year: getInterventionYear(intervention), ...intervention}))
                .filter(intervention => intervention.year === rootGetters["finance/stats/getSelectedYear"])
                .map(intervention => {
                let state = "";
                if (intervention.date_intervention === null && intervention.prestataire !== null) state = "waiting";
                else if (intervention.prestataire === null) state = "unassigned";
                else if (intervention.validation && intervention.date_realisation === null && intervention.waiting === false) state = "assigned";
                else if (intervention.validation && intervention.waiting) state = "running";
                else if (intervention.date_intervention !== null && intervention.validation === false) state = "validation";
                else if (intervention.note === null && intervention.date_realisation !== null) state = "to_note";
                else if (intervention.cout_achat === null && intervention.date_realisation !== null) state = "no_cost";
                else if (intervention.validation && intervention.date_realisation !== null) state = "finished";
                else state = "unknown";
                return ({
                    ...intervention,
                    state: state,
                });
            })
        },
        intervention_unknown(state, getters) {
            return getters['interventions_with_status'].filter(intervention => intervention.state === "unknown");
        },
        count_interventions_by_state: function (state, getters) {
            let interventions = getters['interventions_with_status'];
            let interventions_by_state = groupBy(interventions, "state");
            let count_interventions = {};
            for (const [state, state_interventions] of Object.entries(interventions_by_state)) {
                count_interventions[state] = state_interventions.length;
            }
            return count_interventions;
        },
        interventions_finances: function (state, getters, rootState, rootGetters) {
            let interventions = rootGetters["interventions/interventions"]
                .map(intervention => ({
                    ...intervention,
                    entry_id: intervention.entry,
                    entry: rootGetters["finance/getEntryById"](intervention.entry),
                }))
                .filter(function (intervention) {
                    if(state.selected_site_tree.indexOf("") !== -1) {
                        return true;
                    }
                    if (state.selected_site_tree.length > 0) {
                        return state.selected_site_tree.indexOf(intervention.ticket?.asset?.site?.id) !== -1;
                    } else {
                        return true;
                    }
                });
            return interventions
        },
        interventions_depenses_ponctuelles_par_mois(state, getters, rootState, rootGetters) {
            let interventions = getters["interventions_finances"]
                .map(intervention => ({year: dayjs(intervention.entry?.date_gestion).year(), month: dayjs(intervention.entry?.date_gestion).month(), ...intervention}))
                .filter(intervention => intervention.year === rootGetters["finance/stats/getSelectedYear"] && intervention.entry?.type_depense === "Dépense ponctuelle")
            let interventions_per_month = groupBy(interventions, "month");
            let depense_ponctuelle_per_month = {};
            for (const [month, interventions] of Object.entries(interventions_per_month)) {
                depense_ponctuelle_per_month[month] = interventions
                    .map(intervention => parseFloat(intervention.entry?.realise))
                    .reduce((a, b) => a + b, 0);
            }
            let series = [];
            for (let i = 0; i < 12; i++) {
                series.push(depense_ponctuelle_per_month[i] || 0);
            }
            return series;
        },
        interventions_depenses_sous_contrat_supplementaire_par_mois(state, getters, rootState, rootGetters) {
            let interventions = getters["interventions_finances"]
                .map(intervention => ({year: dayjs(intervention.entry?.date_gestion).year(), month: dayjs(intervention.entry?.date_gestion).month(), ...intervention}))
                    .filter(intervention => intervention.year === rootGetters["finance/stats/getSelectedYear"] && intervention.entry?.type_depense === "Sous contrat supplémentaire");
            let interventions_per_month = groupBy(interventions, "month");
            let depense_casse_per_month = {};
            for (const [month, interventions] of Object.entries(interventions_per_month)) {
                depense_casse_per_month[month] = interventions
                    .map(intervention => parseFloat(intervention.entry?.realise))
                    .reduce((a, b) => a + b, 0);
            }
            let series = [];
            for (let i = 0; i < 12; i++) {
                series.push(depense_casse_per_month[i] || 0);
            }
            return series;
        },
        interventions_depenses_casse_par_mois(state, getters, rootState, rootGetters) {
            let interventions = getters["interventions_finances"]
                .map(intervention => ({year: dayjs(intervention.entry?.date_gestion).year(), month: dayjs(intervention.entry?.date_gestion).month(), ...intervention}))
                .filter(intervention => intervention.year === rootGetters["finance/stats/getSelectedYear"] && intervention.entry?.type_depense === "Casse");
            let interventions_per_month = groupBy(interventions, "month");
            let depense_casse_per_month = {};
            for (const [month, interventions] of Object.entries(interventions_per_month)) {
                depense_casse_per_month[month] = interventions
                    .map(intervention => parseFloat(intervention.entry?.realise))
                    .reduce((a, b) => a + b, 0);
            }
            let series = [];
            for (let i = 0; i < 12; i++) {
                series.push(depense_casse_per_month[i] || 0);
            }
            return series;
        }
    },
};