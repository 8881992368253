import dayjs from "dayjs";

const DateUtilMixin = {
    computed: {
        months() {
            return [
                {"value": null, "name": "Tous les mois"},
                {"value": 0, "name": "Janvier"},
                {"value": 1, "name": "Février"},
                {"value": 2, "name": "Mars"},
                {"value": 3, "name": "Avril"},
                {"value": 4, "name": "Mai"},
                {"value": 5, "name": "Juin"},
                {"value": 6, "name": "Juillet"},
                {"value": 7, "name": "Août"},
                {"value": 8, "name": "Septembre"},
                {"value": 9, "name": "Octobre"},
                {"value": 10, "name": "Novembre"},
                {"value": 11, "name": "Décembre"},];
        },
    },
    methods: {
        getFormattedDate(date) {
            return date != null ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        getReadableDate(date) {
            return dayjs(date).format("DD MMMM YYYY");
        },
        formatDate(date) {
            return dayjs(date).format("DD MMMM YYYY à HH:mm");
        },
        getFormattedDatetime(date, time) {
            return date + " " + time;
        },
        getCleanDate(date, time) {
            return date !== null && time !== null ? dayjs(this.getFormattedDatetime(date, time), "YYYY-MM-DD HH:mm").toISOString() : null;
        },
        getNow() {
            return dayjs().toISOString();
        }
    },
}
export default DateUtilMixin;