import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

import fr from 'vuetify/es5/locale/fr';

export default new Vuetify({
    lang: {
        locales: {fr},
        current: "fr",
    },
    theme: {
        themes: {
            light: {
                primary: "#208774",
                secondary: "#E7B05C",
            }
        }
    }
});
