import dayjs from "dayjs";
var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
function isEntryOfSelectedSite(selected_site_tree, entry) {
    if (selected_site_tree.length > 0 && selected_site_tree.indexOf("") === -1) {
        return selected_site_tree.indexOf(entry.site_id) !== -1
    } else {
        return true
    }
}

export const FinanceStatStore = {
    namespaced: true,
    state: () => ({
        selected_year: dayjs().year(),
        selected_month: null,
        selected_site_tree: [],
    }),
    mutations: {
        setSelectedYear(state, value) {
            state.selected_year = value;
        },
        setSelectedMonth(state, value) {
            state.selected_month = value;
        },
        setSelectedSiteTree(state, value) {
           state.selected_site_tree = value;
        }
    },
    getters: {
        getSelectedYear(state) {
            return state.selected_year;
        },
        getSelectedMonth(state) {
            return state.selected_month;
        },
        getSelectedSiteTree(state) {
            return state.selected_site_tree;
        },
        entry_per_year: function (state, getters, rootState, rootGetters) {
            return rootGetters["finance/allEntries"]
                .map(entry => ({...entry, "asset": rootGetters["assets/getAssetByIdCached"](entry.asset), asset_id:rootGetters["assets/getAssetByIdCached"](entry.asset)?.id}))
                .filter((entry) => state.selected_year === dayjs(entry.date_gestion).year())
                .filter((entry) => state.selected_month !== null ? dayjs(entry.date_gestion).month() === state.selected_month : true)
                .filter(entry => isEntryOfSelectedSite(state.selected_site_tree, entry))
        },
        total_realise_per_year: function (state, getters) {
            return getters["entry_per_year"]
                .filter((entry) => entry.realise !== null)
                .map((entry) => parseFloat(entry.realise))
                .reduce((a, b) => a + b, 0);
        },
        total_previsionnel_per_year: function (state, getters) {
            return getters["entry_per_year"]
                .filter((entry) => entry.type_depense !== "Budget")
                .filter((entry) => entry.previsionnel !== null)
                .map((entry) => parseFloat(entry.previsionnel))
                .reduce((a, b) => a + b, 0) + getters["budget_per_year"];
        },
        budget_per_year(state, getters, rootState, rootGetters) {
            let year_budget = rootGetters["finance/allEntries"]
                .filter(entry => isEntryOfSelectedSite(state.selected_site_tree, entry))
                .filter((entry) => state.selected_year === dayjs(entry.date_gestion).year())
                .filter((entry) => entry.type_depense === "Budget")
                .filter((entry) => entry.previsionnel !== null)
                .map((entry) => parseFloat(entry.previsionnel))
                .reduce((a, b) => a + b, 0);
            return state.selected_month !== null ? year_budget / 12 : year_budget;
        },
        total_realise_depense_ponctuelle(state, getters) {
          return getters["entry_per_year"]
              .filter((entry) => entry.realise !== null)
              .filter((entry) => entry.type_depense === "Dépense ponctuelle")
              .map((entry) => parseFloat(entry.realise))
              .reduce((a, b) => a + b, 0);
        },
        total_realise_sous_contrat(state, getters) {
          return getters["entry_per_year"]
              .filter((entry) => entry.realise !== null)
              .filter((entry) => entry.type_depense === "Dépense sous contrat")
              .map((entry) => Math.round(parseFloat(entry.realise) * 100) / 100)
              .reduce((a, b) => a + b, 0);
        },
        total_realise_casse(state, getters) {
          return getters["entry_per_year"]
              .filter((entry) => entry.realise !== null)
              .filter((entry) => entry.type_depense === "Casse")
              .map((entry) => Math.round(parseFloat(entry.realise) * 100) / 100)
              .reduce((a, b) => a + b, 0);
        },
        total_realise_sous_contrat_supplementaire(state, getters) {
          return getters["entry_per_year"]
              .filter((entry) => entry.realise !== null)
              .filter((entry) => entry.type_depense === "Dépense sous contrat supplémentaire")
              .map((entry) => parseFloat(entry.realise))
              .reduce((a, b) => a + b, 0);
        },
        realise_par_equipement: function(state, getters) {
            let entries = getters["entry_per_year"]
                .filter((entry) => entry.realise !== null && entry.asset !== null)
            let entries_per_equipement = groupBy(entries, "asset_id");
            let realise_per_equipement = {};
            for (const [asset, assetEntries] of Object.entries(entries_per_equipement)) {
                realise_per_equipement[asset] = assetEntries
                    .map((entry) => Math.round(parseFloat(entry.realise) * 100) / 100)
                    .reduce((a, b) => a + b, 0);
            }
            return realise_per_equipement;
        },
        realise_par_contrat: function(state, getters) {
            let entries = getters["entry_per_year"]
                .filter((entry) => entry.realise !== null && entry.contract !== null)
            let entries_per_contract = groupBy(entries, "contract");
            let realise_per_contract = {};
            for (const [contract, contractEntries] of Object.entries(entries_per_contract)) {
                realise_per_contract[contract] = contractEntries
                    .map((entry) => Math.round(parseFloat(entry.realise) * 100) / 100)
                    .reduce((a, b) => a + b, 0);
            }
            return realise_per_contract;
        },
        ecart_per_year: function (state, getters) {
            return  getters["total_realise_per_year"] - getters["total_previsionnel_per_year"];
        },
        active_years: function (state, getters, rootState, rootGetters) {
            return rootGetters["finance/allEntries"]
                .filter((entry) => entry.date_gestion !== null)
                .map((entry) => dayjs(entry.date_gestion).year())
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
        },
    },
};