import axios from "axios";
import config from "../config";
import {TicketFilterStore} from "@/store/tickets/filter";
import {TicketStatStore} from "@/store/tickets/stats";

export const ticketStore = {
    namespaced: true,
    state: () => ({
        "tickets": [],
        "selected_ticket": null,
    }),
    mutations: {
        addTicket(state, ticket) {
            state.tickets = [ticket, ...state.tickets];
        },
        setTickets(state, tickets) {
            state.tickets = tickets;
        },
        appendTickets(state, tickets) {
            state.tickets = [...state.tickets, ...tickets]
        },
        updateTicket(state, ticket) {
            state.tickets = state.tickets.map((item) => item.id === ticket.id ? ticket : item);
        },
        removeTicket(state, ticket) {
            state.tickets = state.tickets.filter((item) => item.id !== ticket.id);
        },
        setSelectedTicket(state, ticket) {
            state.selected_ticket = ticket;
        }
    },
    actions: {
        async fetchTickets({state, commit, dispatch, rootGetters}) {
            let auth = {username: rootGetters["users/getUsername"], password: rootGetters["users/getPassword"]};
            let axiosConfig = rootGetters["users/getLoggedUser"] !== null ? {auth} : {};
            try {
                let response = await axios.get(config.BASE_URL + "/api/v2/tickets/?include=sites", axiosConfig)
                if (response.data.results === undefined) {
                    commit('setTickets', response.data);
                } else {
                    let isFirstLoading = state.tickets.length === 0;
                    if (isFirstLoading) {
                        commit('setTickets', []);
                        commit('appendTickets', response.data.results);
                        let next = response.data.next;
                        while (next !== null) {
                            response = await axios.get(next, axiosConfig);
                            commit('appendTickets', response.data.results);
                            next = response.data.next;
                        }
                    } else {
                        let newTickets = []
                        newTickets = [...newTickets, ...response.data.results];
                        let next = response.data.next;
                        while (next !== null) {
                            response = await axios.get(next, axiosConfig);
                            newTickets = [...newTickets, ...response.data.results];
                            next = response.data.next;
                        }
                        commit("setTickets", newTickets);
                    }
                }
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async fetchTicketsByAsset({commit, dispatch, rootGetters}, asset) {
            let auth = {username: rootGetters["users/getUsername"], password: rootGetters["users/getPassword"]};
            let axiosConfig = rootGetters["users/getLoggedUser"] !== null ? {auth} : {};
            try {
                let response = await axios.get(config.BASE_URL + `/api/tickets/?asset=${asset.id}`, axiosConfig)
                if (response.data.results === undefined) {
                    commit('setTickets', response.data);
                } else {
                    commit('setTickets', []);
                    commit('appendTickets', response.data.results);
                    let next = response.data.next;
                    while (next !== null) {
                        response = await axios.get(next, axiosConfig);
                        commit('appendTickets', response.data.results);
                        next = response.data.next;
                    }
                }
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async getTicketById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/tickets/${id}/?include=sites`, axiosConfig)
                commit('updateTicket', response.data);
                commit('setSelectedTicket', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async postTicket({commit, dispatch, rootGetters}, ticket) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + "/api/tickets/?include=sites", ticket, axiosConfig)
                let newTicket = response.data;
                commit("addTicket", newTicket);
                commit("setSelectedTicket", newTicket);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        /**
         * Mettre à jour le ticket
         * @param commit
         * @param rootGetters
         * @param ticket
         */
        async putTicket({commit, dispatch, rootGetters}, ticket) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/tickets/" + ticket.id + "/?include=sites";
            try {
                let response = await axios.put(url, ticket, axiosConfig);
                var newTicket = response.data;
                commit("updateTicket", newTicket);
                commit("setSelectedTicket", newTicket);
                return newTicket;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async patchTicket({commit, dispatch, rootGetters}, ticket) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/tickets/" + ticket.id + "/?include=sites";
            try {
                let response = await axios.patch(url, ticket, axiosConfig);
                var newTicket = response.data;
                commit("updateTicket", newTicket);
                commit("setSelectedTicket", newTicket);
                return newTicket;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async deleteTicket({commit, dispatch, rootGetters}, ticket) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/tickets/" + ticket.id + "/";
            try {
                await axios.delete(url, axiosConfig);
                commit("removeTicket", ticket);
                commit("setSelectedTicket", null);
                dispatch("messages/announceSuccess", "Ticket supprimé.", {root: true})
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        }
    },
    getters: {
        allTickets(state) {
            return state.tickets;
        },
        affected_tickets(state) {
            return state.tickets.filter((ticket) => ticket.intervention !== null)
        },
        getSelectedTicket(state) {
            return state.selected_ticket;
        },
        demandeurs: function (state, getters) {
            let demandeurs = getters.allTickets
                .filter((ticket) => ticket.created_by !== null)
                .map((ticket) => ({
                "username": ticket.created_by?.username || null,
                "fullname": (ticket.created_by !== null) ? ticket.created_by.first_name + " " + ticket.created_by.last_name : "Utilisateur supprimé",
            }));
            let used_usernames = [];
            return [{"username": "", fullname: ""}, ...demandeurs.filter(function (demandeur) {
                if (demandeur.username in used_usernames) {
                    return false;
                } else {
                    used_usernames.push(demandeur.username);
                    return true;
                }
            })];
        },
        getInterventionTicket(state, getters, _, rootGetters) {
            return function (intervention) {
                let ticket = rootGetters["tickets/allTickets"].find((ticket) => intervention.ticket === ticket.id);
                return ticket === undefined ? null : ticket;
            }
        }
    },
    modules: {
        filter: TicketFilterStore,
        stats: TicketStatStore,
    }
};