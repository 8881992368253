import config from "@/config";
import axios from "axios";

export const helpStore = {
    namespaced: true,
    state() {
        return {
            "requests": [],
        }
    },
    mutations: {
        addRequest(state, value) {
            state.requests = [value, ...state.requests];
        },
        setRequests(state, value) {
            state.requests = value;
        },
        removeRequest(state, value) {
            state.requests = state.requests.filter(request => request?.id !== value?.id);
        },
        updateRequest(state, value) {
            state.requests = state.requests.map(request => request?.id === value?.id ? value : request);
        }
    },
    getters: {
        getRequests(state) {
            return state.requests;
        },
    },
    actions: {
        async fetchRequests(context) {
            let axiosConfig = context.rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(`${config.BASE_URL}/api/help`, axiosConfig);
                context.commit("setRequests", response.data);
            } catch (e) {
                context.dispatch("alerts/handleAxiosError", e, {root:true});
            }
        },
        async postRequest(context, request) {
            let axiosConfig = context.rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(`${config.BASE_URL}/api/help/`, request, axiosConfig);
                context.commit("addRequest", response.data);
            } catch (e) {
                context.dispatch("alerts/handleAxiosError", e, {root:true});
            }
        },
        async deleteRequest(context, request) {
            let axiosConfig = context.rootGetters["users/authentificationConfig"];
            try {
                await axios.delete(`${config.BASE_URL}/api/help/${request.id}/`, axiosConfig);
                context.commit("removeRequest", request);
            } catch (e) {
                context.dispatch("alerts/handleAxiosError", e, {root:true});
            }
        }
    }
}