export const ProfileFilterStore = {
    state() {
        return {
            "search_name": null,
            "filter_group": null,
            "filter_metier": null,
        }
    },
    mutations: {
        setFilter(state, payload) {
            if(payload.filter === "name") {
                state.search_name = payload.value;
            }
        },
        resetFilters(state) {
            state.search_name = null;
        },
        setSearchName(state, value) {
            state.search_name = value;
        },
        setFilterMetier(state, value) {
            state.filter_metier = value;
        },
        setFilterGroup(state, value) {
            state.filter_group = value;
        },
    },
    getters: {
        getFilteredUsers(state, getters) {
            return getters['profiles'].filter(function (user) {
                let valid = true;
                if(state.filter_group === null && ["Prestataire"].indexOf(user.group) !== -1) {
                    valid = false;
                }
                if((state.search_name !== null && state.search_name !== "") && (user.fullname.toLowerCase().indexOf(state.search_name?.toLowerCase()) === -1))
                {
                    valid = false;
                }
                if(state.filter_group !== null && user.group !== state.filter_group) {
                    valid = false;
                }
                return valid;
            });
        },
        getFilteredPrestataires(state, getters) {
            return getters['prestataires'].filter(function (profile) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && profile.fullname.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                if(state.filter_metier !== null && profile.profile.metiers.find((metier) => metier.id === state.filter_metier) === undefined) {
                    valid = false;
                }
                return valid;
            });
        },
        getFilteredCollaborateurs(state, getters) {
            return getters['collaborateurs'].filter(function (profile) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && profile.fullname.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                return valid;
            });
        },
        getFilteredDemandeurs(state, getters) {
            return getters['demandeurs'].filter(function (profile) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && profile.fullname.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                return valid;
            });
        },
        getFilteredResponsables(state, getters) {
            return getters['responsables_techniques'].filter(function (profile) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && profile.fullname.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                return valid;
            });
        },
        getSearchName(state) {
            return state.search_name;
        },
        getFilterMetier(state) {
            return state.filter_metier;
        },
        getFilterGroup(state) {
            return state.filter_group;
        },
    },
    actions: {},
}