export const DrawerMixin = {
    computed: {
        drawerDialog: {
            get() {
                return this.$store.getters["drawer/getDialog"];
            },
            set() {
                return this.$store.commit("drawer/setDialog");
            }
        },
        drawerComponent: {
            get() {
                return this.$store.getters["drawer/getComponent"];
            },
            set() {
                return this.$store.commit("drawer/setComponent");
            }
        },
        drawerProperties: {
            get() {
                return this.$store.getters["drawer/getProperties"];
            },
            set() {
                return this.$store.commit("drawer/setProperties");
            }
        }
    },
    methods: {
        openDrawerDialog(component, properties) {
            this.$vuetify.goTo(0);
            this.$store.dispatch("drawer/openDialog", {component, properties});
        },
        closeDrawerDialog() {
            this.$store.dispatch("drawer/closeDialog");
        },
        closeProperly(open) {
            if(!open) {
                this.closeDrawerDialog();
            }
        }
    }
}