import config from "@/config";
import axios from "axios";

export const periodeStore = {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    setters: {},
    actions: {
        async fetchPeriodesByContract({ dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/periodes/?contract=${contract.id}`;
            try {
                let response = await axios.get(url, axiosConfig);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postPeriode({ dispatch, rootGetters}, periode) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/periodes/`;
            try {
                let response = await axios.post(url, periode, axiosConfig);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async patchPeriode({ dispatch, rootGetters}, periode) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/periodes/${periode.id}/`;
            try {
                let response = await axios.patch(url, periode, axiosConfig);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deletePeriode({ dispatch, rootGetters}, periode) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/periodes/${periode.id}/`;
            try {
                await axios.delete(url, axiosConfig);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
    }
}