<template>
  <div v-if="value_type(value) === 'array'">
    <div v-for="(item, index) in value" :key="index">
      <div v-if="value_type(item) === 'object'">
        <div v-for="(child, field) in item" :key="field">
          <h5>{{ field }}</h5>
          <ul v-if="value_type(child) === 'array'">
            <li v-for="(error, index) in child" :key="index">
              {{ error }}
            </li>
          </ul>
          <ul v-else>
            <li>
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="value_type(value) === 'object'">
    <div v-for="(error, field) in value" :key="field">
      <h5>{{ field }}</h5>
      <ul v-if="value_type(error) === 'string'">
        <li>
          {{ error }}
        </li>
      </ul>
      <ul v-else-if="value_type(error) === 'array'">
        <li v-for="(error, index) in error" :key="index">
          {{ error }}
        </li>
      </ul>
      <ul v-else-if="value_type(error) === 'object'">
        <li v-for="(error, sfield) in error" :key="sfield">
          <strong>{{sfield}}</strong> : {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorList",
  props: ["value"],
  methods: {
    value_type(value) {
      if (Array.isArray(value)) {
        return "array";
      } else if (typeof value === "object") {
        return "object";
      } else if (typeof value === "string") {
        return "string";
      } else {
        return "unknown";
      }
    }
  }
}
</script>

<style scoped>

</style>