export const AssetFilterStore = {
    state() {
        return {
            "search_name": null,
            "selected_category": null,
            "selected_site": null,
            "selected_site_tree": [],
            "site_dialog": false,
        }
    },
    mutations: {
        setFilter(state, payload) {
            if(payload.filter === "name") {
                state.search_name = payload.value;
            }else if(payload.filter === "category") {
                state.selected_category = payload.value;
            }
        },
        resetFilters(state) {
            state.search_name = null;
            state.selected_category = null;
            state.selected_site = null;
            state.selected_site_tree = [];
        },
        setSearchName(state, value) {
            state.search_name = value;
        },
        setFilterCategory(state, value) {
            state.selected_category = value;
        },
        setFilterSite(state, value) {
            state.selected_site = value;
        },
        setFilterSiteTree(state, value) {
            state.selected_site_tree = value;
        },
        setSiteDialog(state, value) {
            state.site_dialog = value;
        }
    },
    getters: {
        getFilteredAssets(state, getters) {
            return getters['allAssets'].filter(function (asset) {
                let valid = true;
                if(state.search_name !== null && state.search_name !== "" && asset.name.toLowerCase().indexOf(state.search_name.toLowerCase()) === -1) {
                    valid = false;
                }
                if(state.selected_category !== null && asset.category === null) {
                    valid = false;
                }
                else if(state.selected_category !== null && state.selected_category !== "" && asset.category.id !== state.selected_category) {
                    valid = false;
                }
                if(state.selected_site !== null && asset.site === null) {
                    valid = false;
                }
                else if(state.selected_site !== null && state.selected_site !== ""  && asset.site?.id !== state.selected_site) {
                    valid = false;
                }
                else if(state.selected_site_tree.length > 0
                    && state.selected_site_tree.indexOf(asset.site?.id) === -1
                    && state.selected_site_tree.indexOf("") === -1) {
                    valid = false;
                }
                if(state.selected_site === null && state.selected_site_tree.length === 0
                    && (state.selected_category === null)
                    && (state.search_name === "" || state.search_name === null)) {
                    valid = false;
                }
                return valid;
            });
        },
        getSearchName(state) {
            return state.search_name;
        },
        getFilterCategory(state) {
            return state.selected_category;
        },
        getFilterSite(state) {
            return state.selected_site;
        },
        getFilterSiteTree(state) {
            return state.selected_site_tree;
        },
        getAssetsSites(state, getters, rootState, rootGetters) {
            return [{"id": "", "name": "Tous les sites"}, ...rootGetters["sites/fullLocationSites"]];
        },
        getSiteDialog(state) {
            return state.site_dialog;
        }
    },
    actions: {},
}