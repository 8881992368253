import axios from "axios";
import config from "../config";
import {ProfileFilterStore} from "@/store/profile/filter";
export const profileStore = {
    namespaced: true,
    state: () => ({
        "profiles": [],
        "selected_profile": null,
    }),
    mutations: {
        addProfile(state, profile) {
            state.profiles = [profile, ...state.profiles]
        },
        setProfiles(state, profiles) {
            state.profiles = profiles;
        },
        updateProfile(state, profile) {
            state.profiles = state.profiles.map((item) => item.id === profile.id ? profile : item);
        },
        removeProfile(state, profile) {
            state.profiles = state.profiles.filter((item) => item.id !== profile.id);
        },
        setSelectedProfile(state, profile) {
            state.selected_profile = profile;
        }
    },
    actions: {
        async fetchProfiles({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            try {
                let response = await axios.get(config.BASE_URL + "/api/profile/", axiosConfig);
                commit('setProfiles', response.data);
            } catch (e) {
                await dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postProfile({commit, dispatch, rootGetters}, profile) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            try {
                let response = await axios.post(config.BASE_URL + "/api/profile/", profile, axiosConfig)
                var newProfile = response.data;
                commit("addProfile", newProfile);
                commit("setSelectedProfile", newProfile);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        /**
         * Mettre à jour le profile
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param profile
         */
        async patchProfile({commit, dispatch, rootGetters}, profile) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + `/api/profile/${profile.id}/`;
            try {
                let response = await axios.patch(url, profile, axiosConfig);
                var newProfile = response.data;
                commit("updateProfile", newProfile);
                commit("setSelectedProfile", newProfile);
            } catch (e) {
                if(e.response.data.toString().indexOf("Ce mot de passe est trop courant.") !== -1) {
                    dispatch("alerts/announceError", {message: "Le mot de passe renseigné est trop courant."}, {root: true})
                } else {
                    dispatch("alerts/handleAxiosError", e, {root: true});
                }
            }
        },
        async getProfileById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/profile/${id}/`, axiosConfig)
                commit('updateProfile', response.data);
                commit('setSelectedProfile', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deleteProfile({commit, dispatch, rootGetters}, profile) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                if(profile.profile?.prestataire) await axios.delete(config.BASE_URL + `/api/prestataires/${profile.profile.prestataire.id}/`, axiosConfig)
                await axios.delete(config.BASE_URL + `/api/profile/${profile.id}/`, axiosConfig)
                commit('removeProfile', profile);
                commit('setSelectedProfile', null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async fetchPrestataireStats({dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            try {
                let response = await axios.get(config.BASE_URL + "/api/profile/stats/", axiosConfig);
                return response.data;
            } catch (e) {
                await dispatch("alerts/handleAxiosError", e, {root: true});
                return [];
            }
        },
    },
    getters: {
        profiles(state) {
            return state.profiles;
        },
        intervenants(state) {
            return state.profiles.filter((item) => ["Prestataire", "Collaborateur", "Responsable technique"].includes(item.group));
        },
        prestataires(state) {
            return state.profiles.filter((item) => item.group === "Prestataire");
        },
        users(state) {
            return state.profiles;
        },
        collaborateurs(state) {
            return state.profiles.filter(item => item.group === "Collaborateur");
        },
        responsables_techniques(state) {
            return state.profiles.filter(item => item.group === "Responsable technique");
        },
        demandeurs(state) {
            return state.profiles.filter(item => item.group === "Demandeur");
        },
        getSelectedProfile(state) {
            return state.selected_profile;
        },
    },
    modules: {filter: ProfileFilterStore}
};