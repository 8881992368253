import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index';
import {ALLOWED_ROUTES, PUBLIC_ROUTES} from "@/constants/rules";
import {routes} from "@/router/routes";
import {getAllowedRoutes} from "@/router/functions";

Vue.use(VueRouter);


class RouterBuilder {
    constructor() {
        this.user = null;
        this.sessionLogged = false;
        this.storageLogged = false;
        this.allowedRoutesNames = [];
        this.router = new VueRouter({
            mode: 'history',
            base: process.env.BASE_URL,
            routes
        });

    }

    async init() {
        this.sessionLogged = await store.dispatch("users/isSessionLogged");
        this.storageLogged = (!this.sessionLogged) ? await store.dispatch("users/isStorageLogged") : false;
        this.user = store.getters["users/getLoggedUser"];
        if(this.sessionLogged || this.storageLogged) {
            this.allowedRoutesNames = getAllowedRoutes(this.user);
        }
    }

    buildRouter() {
        this.router.beforeEach(this.buildRouterGuard.bind(this));
        return this.router;
    }
    async buildRouterGuard(to, from, next) {
        await this.init();
        this.redirectToLoginIfNotConnected(to, next);
        this.redirectToPageIfConnected(to, next);
        await this.redirectToMainPageIfUnauthorized(to, next);
        this.goToPage(to, next);
    }

    goToPage(to, next) {
        document.title = to.meta.title + " - Optia";
        next();
    }

    async redirectToMainPageIfUnauthorized(to, next) {
        if (this.user?.group in ALLOWED_ROUTES && getAllowedRoutes(this.user).indexOf(to.name) === -1) {
            await store.dispatch("messages/announceError", "Vous n'avez pas l'autorisation d'accéder à cette page.");
            next({name: ALLOWED_ROUTES[this.user.group][0]});
        }
    }

    redirectToPageIfConnected(to, next) {
        if (to.name === "Se connecter" && (this.sessionLogged || this.storageLogged) && to.query.path !== undefined) {
            next(to.query.path);
        }
    }

    redirectToLoginIfNotConnected(to, next) {
        if (PUBLIC_ROUTES.indexOf(to.name) === -1 && this.user === null) {
            next({name: "Se connecter", query: {path: to.path}});
        }
    }

}

const routerBuilder = new RouterBuilder();
const router = routerBuilder.buildRouter();

export default router
