import axios from "axios";
import config from "../config";
import {ContractFilterStore} from "@/store/contracts/filter";
import {ContractStatStore} from "@/store/contracts/stats";
export const contractStore = {
    namespaced: true,
    state: () => ({
        "contracts": [],
        "selected_contract": null,
    }),
    mutations: {
        addContract(state, contract) {
            state.contracts = [contract, ...state.contracts]
        },
        setContracts(state, contracts) {
            state.contracts = contracts;
        },
        updateContract(state, contract) {
            state.contracts = state.contracts.map((item) => item.id === contract.id ? contract : item);
        },
        removeContract(state, contract) {
          state.contracts = state.contracts.filter((item) => item.id !== contract.id);
        },
        setSelectedContract(state, contract) {
            state.selected_contract = contract;
        }
    },
    actions: {
        async fetchContracts({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + "/api/contracts/", axiosConfig);
                commit('setContracts', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async getContractById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/contracts/${id}/?include=sites`, axiosConfig)
                commit('updateContract', response.data);
                commit('setSelectedContract', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postContract({commit, dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + "/api/contracts/", contract, axiosConfig);
                var newContract = response.data;
                commit("addContract", newContract);
                commit("setSelectedContract", newContract);
            }  catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        /**
         * Mettre à jour le contract
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param contract
         */
        async putContract({commit, dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/contracts/" + contract.id + "/";
            try {
                let response = await axios.patch(url, contract, axiosConfig);
                var newContract = response.data;
                commit("updateContract", newContract);
                commit("setSelectedContract", newContract);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async deleteContract({commit, dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/contracts/" + contract.id + "/";
            try {
                await axios.delete(url, axiosConfig);
                commit("removeContract", contract);
                commit("setSelectedContract", null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async generateEntries({dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/contracts/" + contract.id + "/generate-entries/";
            try {
                let response = await axios.post(url, null, axiosConfig);
                dispatch("messages/announceSuccess", response.data.message , {root: true})
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deleteOldEntries({dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/contracts/" + contract.id + "/delete-entries/";
            try {
                let response = await axios.delete(url, axiosConfig);
                dispatch("messages/announceSuccess", response.data.message , {root: true})
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async renewContract({dispatch, rootGetters}, contract) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/contracts/" + contract.id + "/renew-contract/";
            try {
                let response = await axios.post(url, null, axiosConfig);
                dispatch("messages/announceSuccess", response.data.message , {root: true})
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async getContractStats({dispatch, rootGetters}, filters) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = new URL(config.BASE_URL + "/api/contracts/stats/");
            url.search = new URLSearchParams(filters).toString();
            try {
                let response = await axios.get(url.toString(), axiosConfig)
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return null;
            }
        }
    },
    getters: {
        allContracts(state) {
            return state.contracts;
        },
        getSelectedContract(state) {
            return state.selected_contract;
        },
        getContractByName(state) {
            return function (name) {
                return state.contracts.find((contract) => contract.name === name);
            }
        }
    },
    modules: {filter: ContractFilterStore, stats: ContractStatStore}
};