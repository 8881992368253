var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function isInterventionInSiteTree(selected_site_tree, intervention) {
    if(selected_site_tree.length === 0) {
        return true;
    } else if(selected_site_tree.indexOf("") !== -1) {
        return true;
    } else if(selected_site_tree.indexOf(intervention.site_id) === -1) {
        return false;
    } else {
        return true;
    }
}

export const AssetStatStore = {
    namespaced: true,
    state() {
        return {
            selected_site_tree: [],
        };
    },
    mutations: {
        setSelectedSiteTree(state, value) {
            state.selected_site_tree = value;
        }
    },
    getters: {
        getSelectedSiteTree(state) {
            return state.selected_site_tree;
        },
        stats_equipement(state, getters, rootState, rootGetters) {
            let interventions = rootGetters["interventions/interventions"].map(interv => ({
                asset_id: interv.ticket?.asset?.id, ...interv,
                site_id: interv.ticket?.asset?.site?.id,
            }))
                .filter(interv => interv.asset_id != null)
                .filter(intervention => isInterventionInSiteTree(state.selected_site_tree, intervention))
            let interventions_equipement = groupBy(interventions, "asset_id");
            let stat_interventions_par_equipement = [];
            for (const [asset_id, asset_interventions] of Object.entries(interventions_equipement)) {
                stat_interventions_par_equipement.push({
                    nombre_interventions: asset_interventions.length,
                    total_realise: asset_interventions
                        .filter(interv => interv.cout_achat !== null)
                        .map(interv => parseFloat(interv.cout_achat))
                        .reduce((a, b) => a + b, 0),
                    asset_id,
                });
            }
            return stat_interventions_par_equipement;
        },
        realise_par_categorie(state, getters, rootState, rootGetters) {
            let interventions = rootGetters["interventions/interventions"].map(interv => ({
                category: interv.ticket?.asset?.category?.name, ...interv,
                site_id: interv.ticket?.asset?.site?.id,
            }))
                .filter(interv => interv.category != null)
                .filter(intervention => isInterventionInSiteTree(state.selected_site_tree, intervention));
            let interventions_categorie = groupBy(interventions, "category");
            let total_par_categorie = {};
            for(const [category, category_interventions] of Object.entries(interventions_categorie)) {
                total_par_categorie[category] = category_interventions
                    .map(interv => parseFloat(interv.cout_achat) || 0)
                    .reduce((a, b) => a + b);
            }
            return total_par_categorie;
        },
    },
}