import axios from "axios";
import config from "../config";
import {InterventionFilterStore} from "@/store/interventions/filter";
import {InterventionStatStore} from "@/store/interventions/stats";
import dayjs from "dayjs";

export const interventionStore = {
    namespaced: true,
    state: () => ({
        "interventions": [],
        "selected_intervention": null,
    }),
    mutations: {
        addIntervention(state, intervention) {
            state.interventions.push(intervention);
        },
        setInterventions(state, interventions) {
            state.interventions = interventions;
        },
        appendInterventions(state, interventions) {
            state.interventions = [...state.interventions, ...interventions]
        },
        updateIntervention(state, intervention) {
            state.interventions = state.interventions.map((item) => item.id === intervention.id ? intervention : item);
        },
        removeIntervention(state, intervention) {
          state.interventions = state.interventions.filter((item) => item.id !== intervention.id)
        },
        setSelectedIntervention(state, intervention) {
            state.selected_intervention = intervention;
        }
    },
    actions: {
        async fetchInterventions({commit, dispatch, rootGetters}) {
            let auth = {username: rootGetters["users/getUsername"], password: rootGetters["users/getPassword"]};
            let axiosConfig = rootGetters["users/getLoggedUser"] !== null ? {auth} : {};
            try {
                let response = await axios.get(config.BASE_URL + "/api/v2/interventions/", axiosConfig)
                if (response.data.results === undefined) {
                    commit('setInterventions', response.data);
                } else {
                    commit('setInterventions', [])
                    commit('appendInterventions', response.data.results);
                    let next = response.data.next;
                    while (next !== null) {
                        response = await axios.get(next, axiosConfig);
                        commit('appendInterventions', response.data.results);
                        next = response.data.next;
                    }
                }
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async postIntervention({commit, dispatch, rootGetters}, intervention) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + "/api/interventions/", intervention, axiosConfig)
                var newIntervention = response.data;
                commit("addIntervention", newIntervention);
                commit("setSelectedIntervention", newIntervention);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        /**
         * Mettre à jour le interventions
         * @param commit
         * @param intervention
         */
        async putIntervention({commit, dispatch, rootGetters}, intervention) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/interventions/" + intervention.id;
            try {
                let response = await axios.put(url, intervention, axiosConfig);
                var newIntervention = response.data;
                commit("updateIntervention", newIntervention);
                commit("setSelectedIntervention", newIntervention);
                return newIntervention;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async patchIntervention({commit, dispatch, rootGetters}, intervention) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/interventions/" + intervention.id;
            try {
                let response = await axios.patch(url, intervention, axiosConfig);
                var newIntervention = response.data;
                commit("updateIntervention", newIntervention);
                commit("setSelectedIntervention", newIntervention);
                return newIntervention;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async deleteIntervention({commit, dispatch, rootGetters}, intervention) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/interventions/" + intervention.id;
            try {
                await axios.delete(url, axiosConfig);
                commit("removeIntervention", intervention);
                commit("setSelectedIntervention", null);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true})
            }
        },
        async getInterventionById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/interventions/${id}`, axiosConfig)
                commit('updateIntervention', response.data);
                commit('setSelectedIntervention', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async sendInterventionReminder({dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/interventions/${id}/reminder/`, axiosConfig);
                await dispatch("messages/setAnnounce", {
                  "type": "success",
                  "message": response.data["message"]
                }, {root: true});
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async generateBon({dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.post(config.BASE_URL + `/api/interventions/${id}/generate/`,{}, axiosConfig);
                let url = response.data.url;
                if (url !== null) {
                    var link = document.createElement("a");
                    link.download = name;
                    link.href = url;
                    link.target = "_blank";
                    link.click();
                }
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        }
    },
    getters: {
        interventions(state) {
            return state.interventions;
        },
        events(state) {
            return state.interventions.filter((intervention) => intervention.validation === true && intervention.prestataire !== null).map((intervention) => {
                let end_date = null;
                if(intervention.date_fin_intervention !== null) {
                    end_date = new Date(intervention.date_fin_intervention);
                } else {
                    end_date = dayjs(intervention.date_intervention).add(1, "hour").toDate();
                }
                return {
                    ...intervention,
                    "start": new Date(intervention.date_intervention),
                    "end": end_date,
                    "title": intervention.ticket.objet,
                    "timed": true,
                    "class": "primary white--text",
                    "intervenant": intervention.prestataire.fullname,
                }
            });
        },
        getSelectedIntervention(state) {
            return state.selected_intervention;
        },
        getLastIntervention: function (state, getters, _, rootGetters) {
            return function (asset) {
                let ticket = rootGetters["tickets/allTickets"].find((ticket) => ticket.asset?.id === asset.id);
                if (ticket === undefined) {
                    return null;
                }
                let intervention = rootGetters["interventions/interventions"].find((intervention) => {
                    return intervention.ticket === ticket.id;
                });
                return intervention !== undefined ? intervention : null;
            };
        }
    },
    modules:{ filter: InterventionFilterStore, stats: InterventionStatStore}
};