function site_name(ticket) {
    let siteObject = ticket.asset?.site;
    if (siteObject === null || siteObject === undefined) return "Non défini";
    let siteText = siteObject.name;
    if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
    }
    if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
    }
    return siteText;
}

export const InterventionFilterStore = {
    state() {
        return {
            filter_state: "",
            filter_site: "",
            filter_name: "",
            filter_ticket_id: null,
            site_tree: [],
        };
    },
    mutations: {
        setFilterState(state, payload) {
            state.filter_state = payload;
        },
        setFilterName(state, payload) {
            state.filter_name = payload;
        },
        setFilterTicketId(state, payload) {
            state.filter_ticket_id = payload;
        },
        setFilterSite(state, payload) {
            state.filter_site = payload;
        },
        setSiteTree(state, value) {
            state.site_tree = value;
        },
        resetFilters(state) {
            state.filter_state = "";
            state.filter_site = "";
            state.filter_name = "";
            state.filter_ticket_id = null;
            state.site_tree = [];
        }
    },
    getters: {
        getFilteredInterventions(state, getters) {
            return getters['interventions'].filter(function (intervention) {
                let valid = true;
                if (state.filter_state !== "all") {
                    if (state.filter_state === "waiting" && !(intervention.date_intervention === null && intervention.validation === false && intervention.prestataire !== null)) {
                        valid = false;
                    }
                    if (state.filter_state === "unassigned" && !(intervention.prestataire === null)) {
                        valid = false;
                    }
                    if (state.filter_state === "assigned" && !(intervention.validation && intervention.date_realisation === null && intervention.waiting === false)) {
                        valid = false;
                    }
                    if (state.filter_state === "running" && !(intervention.validation && intervention.waiting)) {
                        valid = false;
                    }
                    if (state.filter_state === "validation" && !(intervention.date_intervention !== null && intervention.validation === false)) {
                        valid = false;
                    }
                    if (state.filter_state === "to_note" && !(intervention.note === null && intervention.date_realisation !== null)) {
                        valid = false;
                    }
                    if (state.filter_state === "no_cost" && !(intervention.cout_achat === null && intervention.date_realisation !== null)) {
                        valid = false;
                    }
                    if (state.filter_state === "finished" && !(intervention.validation && intervention.date_realisation !== null)) {
                        valid = false;
                    }
                    if (state.filter_state === "" && !(intervention.validation === false || intervention.prestataire === null)) {
                        valid = false;
                    }
                    if (state.filter_site !== "" && site_name(intervention.ticket).indexOf(state.filter_site) === -1) {
                        valid = false;
                    }
                }
                if(state.site_tree.length > 0
                    && state.site_tree.indexOf(intervention.ticket.asset?.site?.id) === -1
                    && state.site_tree.indexOf("") === -1) {
                    valid = false;
                }
                if(state.filter_name !== "" && intervention.ticket?.objet?.toLowerCase().indexOf(state.filter_name?.toLowerCase()) === -1) {
                    valid = false;
                }
                if(state.filter_ticket_id !== "" && state.filter_ticket_id !== null && parseInt(state.filter_ticket_id) !== intervention.ticket.id) {
                    valid = false;
                }
                return valid;
            });
        },
        getFilterState(state) {
            return state.filter_state;
        },
        getFilterName(state) {
            return state.filter_name;
        },
        getFilterTicketId(state) {
            return state.filter_ticket_id;
        },
        getFilterSite(state) {
            return state.filter_site;
        },
        getSiteTree(state) {
            return state.site_tree;
        }
    }
}