import Vue from 'vue'
import Vuex from 'vuex'
import {ticketStore} from "./tickets";
import {assetStore} from "./assets";
import {categoryStore} from "@/store/categories";
import {userStore} from "@/store/users";
import {interventionStore} from "@/store/interventions";
import {messageStore} from "@/store/messages";
import {siteStore} from "@/store/sites";
import {AttachmentStore} from "@/store/attachments";
import {contractStore} from "@/store/contracts";
import {prestataireStore} from "@/store/prestataires";
import {profileStore} from "@/store/profile";
import {groupStore} from "@/store/groups";
import {commentaireStore} from "@/store/commentaires";
import {alertStore} from "@/store/alerts";
import {configStore} from "@/store/config";
import {entryStore} from "@/store/finance";
import {PasswordResetStore} from "@/store/passwordreset";
import {PermissionsStore} from "@/store/permissions";
import {DrawerStore} from "@/store/drawer";
import {helpStore} from "@/store/help";
import {periodeStore} from "@/store/periodes";
import {planStore} from "@/store/plans";
import {emplacementStore} from "@/store/emplacements";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        tickets: ticketStore,
        assets:assetStore,
        categories: categoryStore,
        users: userStore,
        interventions: interventionStore,
        messages: messageStore,
        sites: siteStore,
        attachments: AttachmentStore,
        contracts: contractStore,
        periodes: periodeStore,
        prestataires: prestataireStore,
        profile: profileStore,
        groups: groupStore,
        commentaires: commentaireStore,
        alerts: alertStore,
        config: configStore,
        finance: entryStore,
        passwordreset: PasswordResetStore,
        permissions: PermissionsStore,
        drawer: DrawerStore,
        help: helpStore,
        plans: planStore,
        emplacements: emplacementStore,
    }
})
