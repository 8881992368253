export const messageStore = {
    namespaced: true,
    state: () => ({
        hasMessage: false,
        message: "",
        type: "",
    }),
    getters: {
        hasMessage(state) {
            return state.hasMessage;
        },
        announce(state) {
            return state;
        }
    },
    mutations: {
        announce(state, payload) {
            state.type = payload.type;
            state.message = payload.message;
            state.hasMessage = true;
        },
        end(state) {
            state.type = "";
            state.message = "";
            state.hasMessage = false;
        },
        changeVisibility(state, visible) {
            state.state = visible;
        }
    },
    actions: {
        setAnnounce({commit}, announce) {
            commit("announce", announce);
            setTimeout(() => commit("end"), 5000);
        },
        announceUnauthorized({dispatch}) {
            dispatch("setAnnounce", {"type": "error", "message": "Vous n'êtes pas autorisé à effectuer cette action."})
        },
        announceSuccess({dispatch}, message) {
            dispatch("setAnnounce", {"type": "success", message});
        },
        announceWarning({dispatch}, message) {
            dispatch("setAnnounce", {"type": "warning", message});
        },
        announceError({dispatch}, message) {
            dispatch("setAnnounce", {"type": "error", message});
        }

    },
}