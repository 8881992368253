// eslint-disable-next-line no-unused-vars
import {ALLOWED_ROUTES} from "@/constants/rules";
// eslint-disable-next-line no-unused-vars


export const AuthMixin = {
    computed: {
        logged_user() {
            return this.$store.getters["users/getLoggedUser"];
        },
        isResponsableOrAdmin() {
            return this.logged_user !== null && ['Responsable technique', "Administrateur"].indexOf(this.logged_user.group) !== -1;
        },
        isIntervenant() {
            return this.logged_user !== null && ['Prestataire', 'Collaborateur'].indexOf(this.logged_user.group) !== -1;
        },
        isCollabOrAdmin() {
             return this.logged_user !== null && ['Responsable technique', "Collaborateur", "Administrateur"].indexOf(this.logged_user.group) !== -1;
        },
        isAdmin() {
             return this.logged_user !== null && this.logged_user.group === "Administrateur";
        },
    },
    methods: {
        canAccessRoute(route_name) {
            if(this.logged_user === null) {
                return false;
            } else if (this.isResponsableOrAdmin) {
                return true;
                // route_name in ALLOWED_ROUTES[this.logged_user.group]
            } else if (route_name in ALLOWED_ROUTES[this.logged_user.group]) {
                return true;
            }
        },
        hasPermission(codename) {
            return this.$store.getters["users/hasPermission"](codename);
        }
    }
}