import {ALLOWED_ROUTES} from "@/constants/rules";
import {combineArrays} from "@/util/core";
import {getAllRoutes, routes} from "@/router/routes";

export function getAllowedRoutes(user) {
    let allRoutes = getAllRoutes(routes);
    let permissions = Array.from(user.user_permissions.map(permission => permission.codename));
    let routesAllowedByRole = ALLOWED_ROUTES[user.group] || [];
    let routesAllowedByPermissions = allRoutes
        .filter(route => permissions.indexOf(route.meta.permission) !== -1)
        .map(route => route.name);
    let allowedRoutes = combineArrays(routesAllowedByRole, routesAllowedByPermissions);
    return allowedRoutes;
}