import axios from "axios";
import config from "../config";
function site_name(site) {
      let siteObject = site;
      if (siteObject === null) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
}

export const siteStore = {
    namespaced: true,
    state: () => ({
        "sites": [],
        "selected_site": null,
    }),
    mutations: {
        addSite(state, site) {
            state.sites.push(site);
        },
        setSites(state, sites) {
            state.sites = sites;
        },
        updateSite(state, site) {
            state.sites = state.sites.map((item) => item.id === site.id ? site : item);
        },
        removeSite(state, site) {
            state.sites = state.sites.filter(item => item.id !== site);
        },
        setSelectedSite(state, site) {
            state.selected_site = site;
        }
    },
    actions: {
        fetchSites({commit, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            axios.get(config.BASE_URL + "/api/sites/", axiosConfig)
                .then(function (response) {
                    if (response.status === 200) {
                        commit('setSites', response.data);
                    } else {
                        throw Error("Impossible de charger les sites");
                    }
                });
        },
        async postSite({commit, rootGetters}, site) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let response = await axios.post(config.BASE_URL + "/api/sites/", site, axiosConfig)
            if (response.status === 201) {
                var newSite = response.data;
                commit("addSite", newSite);
                commit("setSelectedSite", newSite);
            } else {
                throw Error(response.data.toString());
            }
        },
        async deleteSite({commit, rootGetters}, site) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            try {
                let response = await axios.delete(config.BASE_URL + "/api/sites/" + site.id + "/", axiosConfig);
                if (response.status >= 200 && response.status <= 300) {
                    commit("removeSite", site);
                    commit("setSelectedSite", null);
                    return "success";
                } else {
                    throw Error(response.data.toString());
                }
            } catch (err) {
                console.error(err);
            }
        },
        /**
         * Mettre à jour le site
         * @param commit
         * @param rootGetters
         * @param site
         */
        async putSite({commit, rootGetters}, site) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/sites/" + site.id;
            let response = await axios.put(url, site, axiosConfig);
            if (response.status >= 200 && response.status <= 300) {
                var newSite = response.data;
                commit("updateSite", newSite);
                commit("setSelectedSite", newSite);
                return newSite;
            } else {
                return null;
            }
        },
        async patchSite({commit, rootGetters}, site) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            const url = config.BASE_URL + "/api/sites/" + site.id +"/";
            let response = await axios.patch(url, site, axiosConfig);
            if (response.status >= 200 && response.status <= 300) {
                var newSite = response.data;
                commit("updateSite", newSite);
                commit("setSelectedSite", newSite);
                return newSite;
            } else {
                return null;
            }
        },
        async generateBarcodes({rootGetters}, site) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/sites/print_barcodes/";
            let response = await axios.post(url, site, axiosConfig);
            if (response.status >= 200 && response.status < 300) {
                return config.BASE_URL + response.data["url"];
            } else {
                return null;
            }
        },
        async generateBarcodesByAsset({rootGetters}, assets) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/sites/print_assets_barcodes/";
            let response = await axios.post(url, assets, axiosConfig);
            if (response.status >= 200 && response.status < 300) {
                return config.BASE_URL + response.data["url"];
            } else {
                return null;
            }
        },
        async generateToLabelBarcodes({rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"]
            const url = config.BASE_URL + "/api/sites/print_to_label_barcodes/";
            let response = await axios.post(url, {}, axiosConfig);
            if (response.status >= 200 && response.status < 300) {
                return config.BASE_URL + response.data["url"];
            } else {
                return null;
            }
        }
    },
    getters: {
        allSites(state) {
            return state.sites.map(site => ({...site, fullname: site_name(site)}));
        },
        sitesTree(state, getters) {
            let allSitesNames = getters["allSites"].map(site => site.name);
            let sitesTree = getters["allSites"].filter(site => site.parent === null || allSitesNames.indexOf(site.parent) === -1);
            for (var j = 0; j < sitesTree.length; j++) {
                sitesTree[j].sites = getters["allSites"]
                    .filter(site => site.parent === sitesTree[j].name);
                for (var i = 0; i < sitesTree[j].sites.length; i++) {
                    sitesTree[j].sites[i].sites = getters["allSites"]
                        .filter(site => site.parent === sitesTree[j].sites[i].name);
                    for (var k = 0; k < sitesTree[j].sites[i].sites.length; k++) {
                        sitesTree[j].sites[i].sites[k].sites = getters["allSites"]
                            .filter(site => site.parent === sitesTree[j].sites[i].sites[k].name);
                    }
                }
            }
            return sitesTree;
        },
        getSelectedSite(state) {
            return state.selected_site;
        },
        fullLocationSites: function (state) {
            return state.sites.map(function (site) {
                let siteObject = site;
                if (siteObject === null) return "Non défini";
                let siteText = siteObject.name;
                if (siteObject.parent !== null) {
                    siteText += `, ${siteObject.parent}`;
                }
                if (siteObject.top !== null) {
                    siteText += `, ${siteObject.top}`;
                }
                return {"id": site.id, name: siteText};
            }).sort();
        }
    }
};