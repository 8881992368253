import axios from "axios";
import config from "@/config";

export const AttachmentStore = {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    getters: {},
    actions: {
        async postTicketAttachment({dispatch, rootGetters}, attachment) {
            let formData = new FormData();
            formData.append("label", attachment.label);
            formData.append("file", attachment.file);
            formData.append("ticket", attachment.ticket);
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let response = await axios.post(config.BASE_URL + `/api/tickets/${attachment.ticket}/attachments/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ...axiosConfig
            });
            if (response.status === 201 || response.status === 200) {
                await dispatch("tickets/getTicketById", attachment.ticket, {root: true});
                return "success";
            } else {
                return "failure";
            }
        },
        async deleteTicketAttachment({dispatch, rootGetters}, attachment) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(config.BASE_URL + `/api/tickets/${attachment.ticket}/delete_attachment/`, attachment, axiosConfig);
                await dispatch("tickets/getTicketById", attachment.ticket, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postAssetAttachment({dispatch, rootGetters}, attachment) {
            let formData = new FormData();
            formData.append("label", attachment.label);
            formData.append("file", attachment.file);
            formData.append("asset", attachment.asset);
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(config.BASE_URL + `/api/assets/${attachment.asset}/attachments/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    ...axiosConfig
                });
                await dispatch("assets/fetchAssetById", attachment.asset, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deleteAssetAttachment({dispatch, rootGetters}, attachment) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(config.BASE_URL + `/api/assets/${attachment.asset}/delete_attachment/`, attachment, axiosConfig);
                await dispatch("assets/fetchAssetById", attachment.asset, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postContractAttachment({dispatch, rootGetters}, attachment) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let formData = new FormData();
            formData.append("label", attachment.label);
            formData.append("file", attachment.file);
            formData.append("contract", attachment.contract);
            try {
                await axios.post(config.BASE_URL + `/api/contracts/${attachment.contract}/attachments/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    ...axiosConfig
                });
                await dispatch("contracts/getContractById", attachment.contract, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deleteContractAttachment({dispatch, rootGetters}, attachment) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(config.BASE_URL + `/api/contracts/${attachment.contract}/delete_attachment/`, attachment, axiosConfig);
                await dispatch("contracts/getContractById", attachment.contract, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },

        async postProfileAttachment({dispatch, rootGetters}, {attachment, profile}) {
            let formData = new FormData();
            formData.append("label", attachment.label);
            formData.append("file", attachment.file);
            formData.append("profile", attachment.profile);
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try{
                await axios.post(config.BASE_URL + `/api/profile/${attachment.profile}/attachments/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    ...axiosConfig
                });
                await dispatch("profile/getProfileById", profile.id, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async deleteProfileAttachment({dispatch, rootGetters}, {attachment, profile}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.post(config.BASE_URL + `/api/profile/${attachment.profile}/delete_attachment/`, attachment, axiosConfig);
                await dispatch("profile/getProfileById",profile.id, {root: true});
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
    }
}