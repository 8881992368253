import config from "@/config";
import * as Sentry from "@sentry/browser";

export const alertStore = {
    namespaced: true,
    state: () => ({
        hasMessage: false,
        title: "",
        message: "",
        type: "",
        details: null,
    }),
    getters: {
        hasMessage(state) {
            return state.hasMessage;
        },
        announce(state) {
            return state;
        }
    },
    mutations: {
        announce(state, payload) {
            state.type = payload.type;
            state.message = payload.message;
            state.title = payload.title;
            state.details = payload.details;
            state.hasMessage = true;
        },
        end(state) {
            state.type = "";
            state.message = "";
            state.title = "";
            state.details = null;
            state.hasMessage = false;
        },
        changeVisibility(state, visible) {
            state.hasMessage = visible;
        }
    },
    actions: {
        setAnnounce({commit}, announce) {
            commit("announce", announce);
        },
        announceSuccess({dispatch}, payload) {
            dispatch("setAnnounce", {
                "type": "success",
                title: "Information",
                message: payload.message,
                details: payload.details
            });
        },
        // eslint-disable-next-line no-unused-vars
        announceWarning({getters, dispatch}, payload) {
            //if(getters["hasMessage"]) return;
            dispatch("setAnnounce", {
                "type": "warning",
                title: "Annonce",
                message: payload.message,
                details: payload.details
            });
        },
        announceError(context, payload) {
            if(context.getters["hasMessage"]) {
                let details = {"Erreur 1": context.state.details};
                if(payload.details !== null) details["Erreur 2"] = payload.details
                context.dispatch("setAnnounce", {
                    type: "error",
                    title: context.state.title,
                    message: `${context.state.message}\n\nUne autre erreur est survenue :\n${payload.message}`,
                    details: details
                })
                return;
            }
            context.dispatch("setAnnounce", {
                "type": "error",
                title: "Une erreur est survenue.",
                message: payload.message,
                details: payload.details
            });
        },
        handleAxiosError: function ({dispatch}, error) {
            if (error.response) {
                if (error.response.status === 500) {
                    dispatch("alerts/announceError", {
                        message: "Un dysfonctionnement dans l'application a été détecté et signalé.\n\nLa dernière opération effectué peut ne pas avoir été bien réalisé.",
                        details: null
                    }, {root: true});
                    switch (config.MODE) {
                        case "production":
                            Sentry.captureException(error);
                            break;
                        default:
                            console.error(error);
                            break;
                    }
                } else if (error.response.status === 400) {
                    let details = error.response?.data !== undefined ? error.response.data : null;
                    dispatch("announceError", {
                        details: details,
                        message: "La dernière opération n'a pas pu être effectuée."
                    })
                } else if (error.response.status === 403) {
                    dispatch("messages/announceUnauthorized",null, {root: true});
                } else if (error.response.status === 404) {
                    dispatch("messages/announceError", "L'élement demandé n'a pas été trouvé.", {root: true});
                }
            } else {
                throw error;
            }
        }
    },
}