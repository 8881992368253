import axios from "axios";
import config from "../config";

export const configStore = {
    namespaced: true,
    state: () => ({
        "configItems": [],
        "selected_configItem": null,
    }),
    mutations: {
        addConfig(state, configItem) {
            state.configItems = [configItem, ...state.configItems];
        },
        setConfigs(state, configItems) {
            state.configItems = configItems;
        },
        updateConfig(state, configItem) {
            state.configItems = state.configItems.map((item) => item.id === configItem.id ? configItem : item);
        },
        removeConfig(state, configItem) {
            state.configItems = state.configItems.filter((item) => item.id !== configItem.id);
        },
        setSelectedConfig(state, configItem) {
            state.selected_configItem = configItem;
        }
    },
    actions: {
        async fetchConfigs({commit, dispatch, rootGetters}) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let url = config.BASE_URL + "/api/config/";
                let response = await axios.get(url, axiosConfig);
                commit('setConfigs', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async getConfigById({commit, dispatch, rootGetters}, id) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.get(config.BASE_URL + `/api/config/${id}/?include=sites`, axiosConfig)
                commit('updateConfig', response.data);
                commit('setSelectedConfig', response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postConfig({commit, dispatch, rootGetters}, configItem) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = null;
                if(configItem.file) {
                    let configItemData = new FormData();
                    configItemData.append("key", configItem.key);
                    configItemData.append("file", configItem.file);
                    axiosConfig["headers"] = {"Content-Type": 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL'};
                    response = await axios.post(config.BASE_URL + "/api/config/", configItemData, axiosConfig);
                }
                else {
                    response = await axios.post(config.BASE_URL + "/api/config/", configItem, axiosConfig);
                }
                var newConfig = response.data;
                commit("addConfig", newConfig);
                commit("setSelectedConfig", newConfig);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        /**
         * Mettre à jour le configItem
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param configItem
         */
        async putConfig({commit, dispatch, rootGetters}, configItem) {
            const url = config.BASE_URL + "/api/config/" + configItem.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                let response = await axios.patch(url, configItem, axiosConfig);
                var newConfig = response.data;
                commit("updateConfig", newConfig);
                commit("setSelectedConfig", newConfig);
                return newConfig;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },

        async deleteConfig({commit, dispatch, rootGetters}, configItem) {
            const url = config.BASE_URL + "/api/config/" + configItem.id + "/";
            let axiosConfig = rootGetters["users/authentificationConfig"];
            try {
                await axios.delete(url, axiosConfig);
                commit("removeConfig", configItem);
                commit("setSelectedConfig", null);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
    },
    getters: {
        allConfigs(state) {
            return state.configItems;
        },
        getSelectedConfig(state) {
            return state.selected_configItem;
        },
        societyName(state) {
            return state.configItems.find((configItem) => configItem.key === 'SOCIETY_NAME');
        },
        societyLogo(state) {
            return state.configItems.find((configItem) => configItem.key === 'SOCIETY_LOGO');
        },
        siteLimit(state) {
            return state.configItems.find((configItem) => configItem.key === 'SITE_LIMIT');
        },
        siteCode(state) {
            return state.configItems.find((configItem) => configItem.key === 'SITE_CODE');
        },
        displayCards(state) {
            return state.configItems.find((configItem) => configItem.key === 'DISPLAY_CARDS');
        },
        showInterventionNumber(state) {
            return state.configItems.find((configItem) => configItem.key === 'SHOW_INTERVENTION_NUMBER');
        }
    }
}